import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
  // cheese carousel // brand carousel
  const cheeseSplideEls = document.querySelectorAll('.cheeseSplide');
  const cheeseBrandSplideEls = document.querySelectorAll('.cheeseBrandSplide');
  // cheese references 
  const cheeseReferenceSplideEls = document.querySelectorAll('.cheeseReferenceSplide');
  // reciep cards
  const recipeSplideEls = document.querySelectorAll('.recipeSplide');

  const optionsCheese = {
    perPage: 4,
    rewind: false,
    pagination: false,
    omitEnd: true,
    breakpoints: {
      991: {
        perPage: 3,
      },
      767: {
        perPage: 1,
      },
    },
    perMove: 1,
    drag: 'free',
    snap: true,
    arrows: true,
    arrowPath: 'm28.19754,9.93881c0.48868,-0.48867 1.28102,-0.48867 1.76971,0l9.17638,9.17643c0.48868,0.48868 0.48868,1.28086 0,1.76954l-9.17638,9.17638c-0.48868,0.48868 -1.28102,0.48868 -1.76971,0c-0.48852,-0.48868 -0.48852,-1.28086 0,-1.76954l7.04029,-7.04029l-33.49666,0c-0.69108,0 -1.25133,-0.56026 -1.25133,-1.25133c0,-0.69107 0.56024,-1.25133 1.25133,-1.25133l33.49666,0l-7.04029,-7.04024c-0.48852,-0.48867 -0.48852,-1.28097 0,-1.76964z',
    classes: {
      arrows: 'btn-row btn-row--right d-none d-md-flex',
      arrow: 'btn',
      prev: 'btn-outline btn--move-left btn-plain ptn-splide--prev icon',
      next: 'btn--filled btn--move-right ptn-splide--next icon',
    },
  };

  const optionsCheeseReference = {
    perPage: 3,
    rewind: false,
    pagination: false,
    omitEnd: true,
    breakpoints: {
      1201: {
        perPage: 2,
      },
      767: {
        perPage: 2,
      },
      576: {
        perPage: 1,
      },
    },
    perMove: 1,
    drag: 'free',
    snap: true,
    arrows: true,
    arrowPath: 'm28.19754,9.93881c0.48868,-0.48867 1.28102,-0.48867 1.76971,0l9.17638,9.17643c0.48868,0.48868 0.48868,1.28086 0,1.76954l-9.17638,9.17638c-0.48868,0.48868 -1.28102,0.48868 -1.76971,0c-0.48852,-0.48868 -0.48852,-1.28086 0,-1.76954l7.04029,-7.04029l-33.49666,0c-0.69108,0 -1.25133,-0.56026 -1.25133,-1.25133c0,-0.69107 0.56024,-1.25133 1.25133,-1.25133l33.49666,0l-7.04029,-7.04024c-0.48852,-0.48867 -0.48852,-1.28097 0,-1.76964z',
    classes: {
      arrows: 'btn-row btn-row--right d-none d-md-flex',
      arrow: 'btn',
      prev: 'btn-outline btn--move-left btn-plain ptn-splide--prev icon',
      next: 'btn--filled btn--move-right ptn-splide--next icon',
    },
  };

  const optionsReciep = {
    perPage: 3,
    rewind: false,
    pagination: false,
    omitEnd: true,
    breakpoints: {
      1201: {
        perPage: 2,
      },
      767: {
        perPage: 1,
      },
    },
    perMove: 1,
    drag: 'free',
    snap: true,
    arrows: true,
    arrowPath: 'm28.19754,9.93881c0.48868,-0.48867 1.28102,-0.48867 1.76971,0l9.17638,9.17643c0.48868,0.48868 0.48868,1.28086 0,1.76954l-9.17638,9.17638c-0.48868,0.48868 -1.28102,0.48868 -1.76971,0c-0.48852,-0.48868 -0.48852,-1.28086 0,-1.76954l7.04029,-7.04029l-33.49666,0c-0.69108,0 -1.25133,-0.56026 -1.25133,-1.25133c0,-0.69107 0.56024,-1.25133 1.25133,-1.25133l33.49666,0l-7.04029,-7.04024c-0.48852,-0.48867 -0.48852,-1.28097 0,-1.76964z',
    classes: {
      arrows: 'btn-row btn-row--right d-none d-md-flex',
      arrow: 'btn',
      prev: 'btn-outline btn--move-left btn-plain ptn-splide--prev icon',
      next: 'btn--filled btn--move-right ptn-splide--next icon',
    },
  };

  function initSlider(element, opt) {
    //console.log(element, opt);

    const slides = element.querySelectorAll('.splide__slide');
    
    // get breakpoint settings
    // eslint-disable-next-line no-restricted-syntax
    for (const breakpoint in opt.breakpoints) {
      if (parseInt(breakpoint, 10) > document.body.offsetWidth) {
        opt.perPage = opt.breakpoints[breakpoint].perPage;
        break;
      }
    }
    //console.log(opt.perPage, slides.length);
    // init splide slider or set width
    if (opt.perPage < slides.length) {
      element.classList.add('splide');
      element.querySelector('.splide__list').classList.remove('splide--no-slider');
      
      const splide = new Splide(element, opt);
      const indexMax = Math.ceil(slides.length - opt.perPage);
      const splideTrackContainer = element.querySelector('.splide__track-container');
      splide.on('move', (newIndex, prevIndex, destIndex) => {
        if (newIndex === indexMax) {
          splideTrackContainer.classList.add('splide-last');
        } else {
          splideTrackContainer.classList.remove('splide-last');
        }
        if (newIndex === 0) {
          splideTrackContainer.classList.add('splide-first');
        } else {
          splideTrackContainer.classList.remove('splide-first');
        }
      });

      splide.mount();
    } else {
      // set slider width
      const sWidth = `${(100 / opt.perPage)}%`;
      for (let s = 0; s < slides.length; s++) {
        slides[s].style.width = sWidth;
      }
      element.style.visibility = 'initial';
      element.classList.add('splide--no-slider');
      //console.log('no-slider: ', element, opt.perPage, sWidth);
    }
    //console.log('element: ',element);
  }

  for (let i = 0; i < cheeseSplideEls.length; i++) {
    initSlider(cheeseSplideEls[i], optionsCheese);
  }

  for (let i = 0; i < cheeseBrandSplideEls.length; i++) {
    initSlider(cheeseBrandSplideEls[i], optionsCheese);
  }

  for (let i = 0; i < cheeseReferenceSplideEls.length; i++) {
    initSlider(cheeseReferenceSplideEls[i], optionsCheeseReference);
  }

  for (let i = 0; i < recipeSplideEls.length; i++) {
    initSlider(recipeSplideEls[i], optionsReciep);
  }
});
