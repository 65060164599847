class Glossar {
  constructor(el) {
    this.glossarMenu = el;
    this.anchors = document.querySelectorAll('.js-glossar-anchor-click');

    this.initElements();
    this.initEventListener();
    this.checkStickyTop();
  }

  initElements() {
    this.posY = this.glossarMenu.getBoundingClientRect().top + window.pageYOffset;
    this.checkStickyTop();
    // this.listHeight = document.querySelectorAll('.dpnglossary').getBoundingClientRect().height;
  }

  initEventListener() {
    window.onscroll = this.checkStickyTop.bind(this);
    window.onresize = this.initElements.bind(this);
  }

  checkStickyTop() {
    if (window.pageYOffset >= this.posY) {
      this.glossarMenu.classList.add('sticky');
    } else {
      this.glossarMenu.classList.remove('sticky');
    }
  }
}
// named export (same as previous code snippet)
export { Glossar };
