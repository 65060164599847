import { Collapse } from 'bootstrap';

const collapseElementList = [].slice.call(document.querySelectorAll('.collpase--readmore'));
const HEADER_COLLAPSE_TOLERANCE = 50;
collapseElementList.map((collapseEl) => {
  const height = collapseEl.offsetHeight;
  collapseEl.style.height = 'auto';

  if (collapseEl.offsetHeight > height + HEADER_COLLAPSE_TOLERANCE) {
    collapseEl.style.removeProperty('height');
    return new Collapse(collapseEl, { toggle: false });
  }
  collapseEl.closest('.readMore').classList.add('readMore--fixed');
});
