// eslint-disable-next-line import/no-extraneous-dependencies
import anime from 'animejs/lib/anime.es';

const infographicContainer = document.querySelector('#infographic');
const points = document.querySelectorAll('[data-infographic-point-value]');
function initPoints() {
  console.log(points);
  for (let i = 0; i < points.length; i++) {
    const el = points[i];
    const value = Math.max(1, el.getAttribute('data-infographic-point-value'));
    console.log(el.getAttribute('data-infographic-point-value')," ->",value, Math.sqrt(value));
    el.style.setProperty('--size', Math.sqrt(value));
  }
}

function initInfographic() {
  const infographicLineSVG = document.querySelector('#infographicSVG');
  const infographicLinePath = document.querySelector('#infographicPath');
  const infographicLineDot = document.querySelector('#infographicDot');
  const infographicPoints = document.querySelectorAll('.infographic-point');
  const pointsArray = Array.from(infographicPoints);

  const triggerPosition = 0.4;
  const path = anime.path('#infographicPath');

  function drawLine() {
    const svgWidth = infographicContainer.getBoundingClientRect().width;
    const svgHeight = infographicContainer.getBoundingClientRect().height;

    infographicLineSVG.setAttribute('width', svgWidth);
    infographicLineSVG.setAttribute('height', svgHeight);

    const xOffset = infographicContainer.getBoundingClientRect().left;
    const yOffset = infographicContainer.getBoundingClientRect().top;

    let linestring = '';
    let prevPoint = null;

    infographicPoints.forEach((element, index) => {
      const rect = element.getBoundingClientRect();
      const x = Math.round(rect.left - xOffset + rect.width * 0.5);
      const y = Math.round(rect.top - yOffset + rect.height * 0.5);

      if (index === 0) {
        linestring = `M ${x} ${y}`;
      } else {
        // Draw a cubic bézier curve from the previous point to the current one
        const x1 = prevPoint.x;
        const y1 = prevPoint.y + (y - prevPoint.y) / 2;
        const x2 = x;
        const y2 = y1;

        linestring += ` C ${x1}, ${y1} ${x2}, ${y2} ${x}, ${y}`;
      }

      prevPoint = { x, y };
    });

    infographicLinePath.setAttribute('d', linestring);
  }

  drawLine();

  let dotAnime;

  // animate on path
  function setupDotAnime() {
    if (dotAnime) {
      dotAnime.pause();
      dotAnime.remove();
    }

    dotAnime = anime({
      targets: infographicLineDot,
      translateX: path('x'),
      translateY: path('y'),
      easing: 'linear',
      duration: 1,
      autoplay: false,
    });
  }
  setupDotAnime();

  function checkIntersection(pointBounds, directionDown) {
    pointsArray.forEach((point) => {
      // Determine if the point should have 'intersect' class
      const pointRect = point.getBoundingClientRect();
      const parent = point.closest('.row--infographic-item');
      if (!parent) {
        return;
      }

      if (directionDown) {
        if (pointRect.top < pointBounds.top) {
          parent.classList.add('intersect');
        } else {
          parent.classList.remove('intersect');
        }
      } else if (pointRect.top > pointBounds.top) {
        parent.classList.remove('intersect');
      } else {
        parent.classList.add('intersect');
      }
    });
  }

  let progress;
  function animateLine() {
    /* eslint-disable */
    const nextProgress =
      (infographicLinePath.getBoundingClientRect().top - window.innerHeight * triggerPosition) *
      (1 / infographicLinePath.getBoundingClientRect().height) *
      -1;
    /* eslint-enable */

    const direction = nextProgress > progress;

    progress = nextProgress;
    if (progress < 0) {
      progress = 0;
    } else if (progress > 1) {
      progress = 1;
    }

    checkIntersection(infographicLineDot.getBoundingClientRect(), direction);

    dotAnime.seek(progress);
  }
  animateLine();

  window.addEventListener('scroll', () => {
    // call debounced animate
    window.requestAnimationFrame(() => {
      animateLine();
    });
  });

  window.addEventListener('resize', () => {
    window.requestAnimationFrame(() => {
      drawLine();
      setupDotAnime();
    });
  });
}

if (infographicContainer) {
  initPoints();
  initInfographic();
}
