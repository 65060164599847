const popupContainer = document.querySelector('[data-popup-id]');

class Popup {
  constructor(container) {
    this.container = container;
    this.initElements();
    if (this.getCookie(this.popupId)) {
      //console.log('dont show', this.getCookie(this.popupId));
    } else {
      this.initEventListener();
      this.showPopup(2000);

      if (this.formmessage) {
        //this.showPopup(500); // open instant
        this.closePopup(100); // auto close after sec
      }
    }
  }

  initElements() {
    this.closePopupButton = this.container.querySelector('.js-close-popup');
    this.popupId = this.container.getAttribute('data-popup-id');
    this.formmessage = this.container.querySelector('[data-newsletter-subscribe-success]');
  }

  initEventListener() {
    if (this.closePopupButton) {
      this.closePopupButton.addEventListener('click', (e) => {
        this.closePopup();
      });
    }
  }

  showPopup(delay = 0) {
    clearTimeout(this.openTimeout);
    this.openTimeout = setTimeout(() => {
      this.container.classList.add('open');
      document.body.classList.add('popup-open');
    }, delay, this);
  }

  closePopup(delay = 0) {
    clearTimeout(this.openTimeout);
    clearTimeout(this.closeTimeout);
    this.closeTimeout = setTimeout(() => {
      this.container.classList.remove('open');
      this.setCookie(this.popupId);
      document.body.classList.remove('popup-open');
    }, delay, this);
  }

  // cookie
  setCookie(id) {
    let expires = '';
    const days = 60;
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
    document.cookie = `${`${id}=${date}` || `${expires}`}; path=/`;
  }

  getCookie(id) {
    const nameEQ = `${id}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}

if (popupContainer) {
  const popup = new Popup(popupContainer);
}
