/** ************ HOME HEADER ANIMATION  ************* */
const headervideo = document.querySelector('#headerVideo'); // js-ready-fade-in
const headercontent = document.querySelectorAll('.js-ready-fade-in');
const Sentry = window.Sentry || { captureMessage: console.log };

// Create a promise that resolves when the video is ready or a fallback timeout occurs
const videoReadyPromise = new Promise((resolve) => {
  if (!headervideo) {
    resolve('no-video');
    return;
  }
  // Listen for 'canplay' event to ensure the video can be played
  headervideo.addEventListener('canplay', () => {
    resolve('canplay');
  });

  // Listen for the 'loadedmetadata' event as a backup (video data is ready)
  headervideo.addEventListener('loadedmetadata', () => {
    resolve('loadedmetadata');
  });

  // Listen for 'play' event in case the video starts playing before 'canplay'
  headervideo.addEventListener('play', () => {
    resolve('play');
  });

  // Fallback: Resolve if the video is not ready within 2 seconds
  setTimeout(() => {
    resolve('timeout');
  }, 2000);
});

// Function to handle setting the "video-ready" class
function setVideoReadyState() {
  headercontent.forEach((el) => {
    el.classList.add('video-ready');
  });
  headervideo.closest('header').classList.add('loaded');
}

function initHeaderVideo() {
  if (!headervideo) {
    headercontent.forEach((el) => {
      el.classList.add('video-ready');
    });
    return;
  }

  // Fallback for visibility changes (e.g., if the video pauses when page is hidden)
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && headervideo.paused) {
      headervideo.play().catch((err) => {
        Sentry.captureMessage(`Video failed to play on visibility change: ${err}`, 'error');
      });
    }
  });

  // Execute once video is ready or after timeout
  videoReadyPromise.then(() => {
    setVideoReadyState();
  });
}

document.addEventListener('DOMContentLoaded', initHeaderVideo, false);
