const progresbar = document.querySelector('[data-infographic-progresbar]');

function initProgressbar() {
  const progresbarItems = progresbar.querySelectorAll('[data-progresbar-item-index]');

  const options = {
    root: null,
    rootMargin: '0% 0% -30% 0%',
    threshold: 0.5,
  };

  // initial, active, past
  function setProgresBarStyle(index, style) {
    const item = progresbarItems[index];
    delete item.dataset.progressStyle;
    item.dataset.progressStyle = style;
  }

  function setProgressBarActive(index) {
    progresbarItems.forEach((_, i) => {
      if (i < index) {
        progresbarItems[i].dataset.progressStyle = 'past';
      }
    });
    progresbarItems[index].dataset.progressStyle = 'active';
  }

  const callback = (entries) => {
    entries.forEach((entry) => {
      const { isIntersecting } = entry;
      const index = entry.target.dataset.infographicItemIndex;
      const { top } = entry.boundingClientRect;

      if (isIntersecting) {
        setProgressBarActive(index);
      } else if (top > 0) {
        setProgresBarStyle(index, 'initial');
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);

  const infographicItems = document.querySelectorAll('.row--infographic-item');
  infographicItems.forEach((item) => {
    observer.observe(item);
  });

  function getYOffset(el) {
    const rect = el.getBoundingClientRect();
    return rect.top + window.scrollY;
  }

  progresbarItems.forEach((item) => {
    item.addEventListener('click', () => {
      const targetId = item.getAttribute('href');
      const targetEl = document.querySelector(targetId).querySelector('.infographic-point');
      const { height } = targetEl.getBoundingClientRect(); // point height
      const targetWindowOffset = window.innerHeight * 0.4; // 0.4 = InforgraphicLine triggerPosition
      const targetscroll = getYOffset(targetEl) - targetWindowOffset + height * 0.5;
      window.scroll({
        top: targetscroll,
        behavior: 'smooth',
      });
    });
  });
}

if (progresbar) {
  initProgressbar();
}
