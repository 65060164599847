import { Carousel } from 'bootstrap';

const focusteaserCarousels = document.querySelectorAll('.focusteaser-carousel');
const carousels = [];
focusteaserCarousels.forEach((element) => {
  element.classList.add('carousel');
  const carousel = new Carousel(element, {
    interval: 5000,
    pause: 'hover',
  });
  carousels.push(carousel);
});

function checkCarousels(scrollPos) {
  carousels.forEach((carousel) => {
    if (carousel._element.getBoundingClientRect().top < 0) {
      carousel.pause();
    }else{
        carousel.cycle();
    }
  });
}
if (carousels) {
  let lastKnownScrollPosition = 0;
  let ticking = false;

  document.addEventListener('scroll', (e) => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        checkCarousels(lastKnownScrollPosition);
        ticking = false;
      });

      ticking = true;
    }
  });
}
