import Chart from 'chart.js/auto';

const charts = [];

const defaultColors = ['#E30613', '#FF4D4D', '#FFD373', '#FFA500', '#FF7373', '#FF9999', '#FFBFBF'];

const chartCanvasElements = document.querySelectorAll('[data-donut-chart]');
chartCanvasElements.forEach((chartContainer) => {
  const chartCanvas = chartContainer.querySelector('canvas');
  const jsonData = JSON.parse(chartContainer.dataset.chartData);
  const data = jsonData.map((item) => ({
    value: parseFloat(item[0]),
    label: item[1],
    color: item[2],
  }));
  const unit = chartContainer.dataset.chartUnit;
  const title = chartContainer.dataset.chartTitle;

  const cfg = {
    type: 'doughnut',
    data: {
      labels: data.map((item) => item.label),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: data.map((item, index) => item.color || defaultColors[index]),
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          position: 'bottom',
          align: 'center',
        },
        centerText: {
          display: !!title,
          text: title || '',
        },
        tooltip: {
          boxPadding: 6,
          callbacks: {
            title: () => '',
            label: (context) => {
              const label = context.label || '';
              const value = context.parsed || 0;
              return `${label}: ${value} ${unit || ''}`;
            },
          },
        },
      },
    },
  };

  Chart.register({
    id: 'centerText',
    afterDatasetDraw(chart, args, pluginOptions) {
      // Function to wrap text into lines
      function wrapText(text, maxWidth, ctx) {
        const words = text.split(' ');
        const lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i += 1) {
          const word = words[i];
          const { width } = ctx.measureText(`${currentLine} ${word}`);
          if (width < maxWidth) {
            currentLine += ` ${word}`;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines;
      }

      const { ctx } = chart;
      const { width } = chart;
      const { meta } = args;

      // Ensure we're working with the doughnut chart's metadata
      if (meta.type !== 'doughnut') return;

      const doughnutCenter = meta.data[0]; // Get the center point from the first arc

      // Get the coordinates of the center of the doughnut
      const xCoor = doughnutCenter.x;
      const yCoor = doughnutCenter.y;

      const { text } = chart.config.options.plugins.centerText;
      const fontFamily = getComputedStyle(document.body)
        .getPropertyValue('--bs-headings-font-family')
        .trim();

      const cutoutRadius = meta.data[0].innerRadius;

      if (chart.config.options.plugins.centerText.display) {
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        let fontSize = width > 360 ? 40 : 24;
        ctx.font = `${fontSize}px ${fontFamily}`;

        // Calculate the maximum available space for text
        const maxTextWidth = cutoutRadius; // Limit width slightly to avoid touching edges
        const maxTextHeight = cutoutRadius; // Limit height to fit multiple lines

        let lines = wrapText(text, maxTextWidth, ctx);

        // Adjust font size to fit the text within the cutout
        while (fontSize > 5) {
          // Set a minimum font size limit
          const textHeight = fontSize * lines.length;

          if (lines.length <= 3 && textHeight <= maxTextHeight) {
            break; // The text fits within the available space
          }

          fontSize -= 1; // Decrease font size until the text fits
          ctx.font = `${fontSize}px ${fontFamily}`;
          lines = wrapText(text, maxTextWidth, ctx); // Re-wrap text with new font size
        }

        // Calculate the starting Y position for the text block
        const startY = yCoor - (fontSize * lines.length) / 2 + fontSize / 2;

        // Draw each line of text
        lines.forEach((line, index) => {
          const textY = startY + index * fontSize;
          ctx.fillText(line, xCoor, textY);
        });

        ctx.restore();
      }
    },
  });

  const inModal = chartContainer.closest('.modal-chart-wrapper');
  // if chart is in modal initiate only once modal is open
  if (inModal) {
    const modal = inModal.closest('.modal');
    modal.addEventListener('shown.bs.modal', () => {
      const chart = new Chart(chartCanvas, cfg);
      charts.push([chartCanvas.id, chart]);
    });
  } else {
    const chart = new Chart(chartCanvas, cfg);
    charts.push([chartCanvas.id, chart]);
  }
});
