import { InvinitySroll } from './InvinitySroll';

const HISTORY_SUPPORT = !!(history && history.pushState);

const SOLR_CONTAINER_CLASS = '#tx-solr-search';
const LOADING_CL = 'is-loading-result';
const searchContainer = document.querySelector(SOLR_CONTAINER_CLASS);

const SEARCH_INPUT_ID = '#finderSearchInput';
const FORM_ID = '#tx-solr-search-form-pi-results';
class SolrSearch {
  constructor() {
    if (searchContainer) {
      this.initElements();
      this.invintiyScroll = new InvinitySroll();
      this.initEvenlistener();
    }
  }

  initElements() {
    this.searchinputEl = document.querySelector(SEARCH_INPUT_ID);
    this.form = document.querySelector(FORM_ID);
  }

  initEvenlistener() {
    window.addEventListener('popstate', (event) => {
      this.doSearch(`${document.location}`);
    });

    this.form.addEventListener('submit', (event) => {
      event.preventDefault();
      const queryString = new URLSearchParams(new FormData(event.target)).toString();
      const action = event.target.getAttribute('action');
      const uri = `${action}?${queryString}`
      this.doSearch(uri);

      if (HISTORY_SUPPORT) {
        history.pushState({}, '', uri);
      }
    });
  }

  doSearch(uri) {
    document.body.classList.add(LOADING_CL);

    fetch(uri, {
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((res) => res.text()).then((html) => {
      this.updateSearch(html);
      this.invintiyScroll.initPagination();
      document.body.classList.remove(LOADING_CL);
    }).catch((err) => {
      console.warn('Something went wrong.', err);
      document.body.classList.remove(LOADING_CL);
    });
  }

  updateSearch(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    for (const el of doc.getElementsByClassName('js-ajax-replace')) {
      const id = el.getAttribute('id');
      const node = document.getElementById(id);
      node.innerHTML = el.innerHTML;
    }
  }
}
export { SolrSearch };
