// default animation trigger
const scrollbuttons = document.querySelectorAll(".js-scoll-down");
const headerScrollTop = 150;
for (const button of scrollbuttons) {
  button.addEventListener("click", clickScollButton);
}

function clickScollButton(e) {
  e.preventDefault();
  const offsetTop = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  window.scroll({
    top: offsetTop - headerScrollTop,
    behavior: "smooth",
  });
};