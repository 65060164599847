/**
*
*/
const rating = function () {
  const root = this;
  root.canvote = true;
  root.dropup = document.querySelector('#rateDropUp');

  root.init = function () {

    if (root.dropup) {
      root.container = root.dropup.querySelector('#rateStars');
      root.id = root.container.getAttribute('data-id');
      root.uri = root.container.getAttribute('data-uri');
    } else {
      return;
    }

    if (localStorage.getItem(`rate-${root.id}`) === 'voted') {
      const votevalue = localStorage.getItem(`rate-${root.id}-val`);
      const starElement = root.dropup.querySelector(`input[value="${votevalue}"]`);
      starElement.checked = true;
      root.deactivateVoting();
      return;
    }

    if (root.dropup.classList.contains('voted')) {
      root.deactivateVoting();
      return;
    }

    root.stars = root.container.querySelectorAll('input');
    for (let i = 0; i < root.stars.length; i++) {
      root.stars[i].addEventListener('click', root.starClick);
    }
  };

  root.starClick = function () {
    root.voting = this.getAttribute('value');
    localStorage.setItem(`rate-${root.id}-val`, root.voting);
    localStorage.setItem(`rate-${root.id}`, 'voted');
    root.ajaxRequest();
  };

  this.ajaxRequest = function () {
    root.voting = root.voting || 0;
    const ajaxUrl = `${root.uri}`;
    fetch(ajaxUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'tx_issitescm_recipe%5Bvalue%5D=' + root.voting
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          document.querySelector('#rateState').setAttribute('data-total-rating', Math.round(json[0].totalRating));
          document.querySelector('#ratingValue').innerHTML = Math.round(json[0].totalRating);
          document.querySelector('#ratingCount').innerHTML = json[0].countRating;
          root.deactivateVoting();
          console.log(json);
        } else {
          console.log(json.message);
        }
      });
  };

  root.deactivateVoting = function () {
    root.canvote = false;
    root.dropup.classList.add('voted');
  };

  root.init();
};

const myrating = new rating();
