const PAGINATION_LINK_SELECTOR = '.page-link';
const DATA_URI = 'data-href';
const LOADING_PAGINATION_CL = 'is-loading-page';

// pagination
const DEFAULTMARGINBOTTOM = 30;
const DEFAULTMARGINTOP = 0;
const DEFAULTTHRESHOLD = 0;

class InvinitySroll {
  constructor() {
    this.lazyBackgroundObserver = new IntersectionObserver(this.loadNextPage.bind(this), {
      rootMargin: `-${DEFAULTMARGINTOP}% 0% -${DEFAULTMARGINBOTTOM}% 0%`,
      threshold: DEFAULTTHRESHOLD,
    });
    this.initPagination();
  }

  initPagination() {
    const paginationButton = document.querySelector(PAGINATION_LINK_SELECTOR);
    if (paginationButton && this.lazyBackgroundObserver) {
      // viewport trigger pagination
      this.lazyBackgroundObserver.observe(paginationButton);
      // click trigger pagination
      paginationButton.addEventListener('click', (e) => {
        e.preventDefault();
        const uri = e.target.getAttribute(DATA_URI);
        this.doPagination(uri);
        e.target.blur();
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  loadNextPage(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const uri = entry.target.getAttribute(DATA_URI);
        this.doPagination(uri);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  doPagination(uri) {
    document.body.classList.add(LOADING_PAGINATION_CL);
    fetch(uri, {
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((res) => res.text()).then((html) => {
      // this.updatePagination(html);
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const newList = doc.getElementById('JsSearchResultList');
      const target = document.getElementById('JsSearchResultList');
      target.innerHTML += newList.innerHTML;

      const nextPagEl = doc.querySelector(PAGINATION_LINK_SELECTOR);
      const currentPagEl = document.querySelector(PAGINATION_LINK_SELECTOR);

      if (nextPagEl) {
        currentPagEl.setAttribute(DATA_URI, nextPagEl.getAttribute(DATA_URI));
      } else if (currentPagEl) {
        currentPagEl.remove();
      }
      document.body.classList.remove(LOADING_PAGINATION_CL);
    }).catch((err) => {
      console.warn('Something went wrong.', err);
      document.body.classList.remove(LOADING_PAGINATION_CL);
    });
  }
}
export { InvinitySroll };
