// toggle main menu
const langdropdowns = document.querySelectorAll('.js-lang-dropdown-btn');
const mainNav = document.querySelector('.mainNav');
const mainButton = document.querySelector('#languageRegionSwitchButton');

langdropdowns.forEach((button) => {
  button.addEventListener('click', (e) => {
    e.stopPropagation();
    e.preventDefault();
    langdropdowns.forEach((button) => {
      if (button === e.target) {
        button.parentElement.classList.toggle('open');
      } else {
        button.parentElement.classList.remove('open');
      }
    });
  });
});

if(mainButton){
  mainButton.addEventListener('show.bs.dropdown', (event) => {
    mainButton.style.transform = 'none';
  });
}
if(mainNav){
  mainNav.addEventListener('hide.bs.dropdown', (event) => {
    langdropdowns.forEach((button) => {
      button.parentElement.classList.remove('open');
    });
  });
  // close all
  mainNav.addEventListener('hide.bs.dropdown', (event) => {
    langdropdowns.forEach((button) => {
      button.parentElement.classList.remove('open');
    });
  });
}
