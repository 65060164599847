import { Tooltip } from 'bootstrap';

const openTooltips = [];
let onlastOpenScrolPos = window.scrollY;
const MINSCROLLTOHIDEALL = 200;
const isToucheDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
const tooltipsCloseOnClickOutside = [];

tooltipTriggerList.map((tooltipTriggerEl) => {
  const tooltip = new Tooltip(tooltipTriggerEl);
  if (tooltipTriggerEl.getAttribute('data-close-outside')) {
    tooltipsCloseOnClickOutside.push(tooltip);
  }
  // mobile
  if (isToucheDevice) {
    tooltipTriggerEl.addEventListener('touchstart', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (tooltip.isVisible) {
        tooltip.hide();
        tooltip.isVisible = false;
        console.log(tooltipTriggerEl.getAttribute('href'));
        window.location = tooltipTriggerEl.getAttribute('href');
        return;
      }

      tooltip.isVisible = true;
      tooltip.show();
      onlastOpenScrolPos = window.scrollY;
      openTooltips.push(tooltip);
    });
  }
});

// close tooltip on outside click
window.document.body.addEventListener('click', (e) => {
  if (!e.target.getAttribute('data-close-outside')) {
    tooltipsCloseOnClickOutside.forEach((tooltip) => {
      tooltip.hide();
    });
  }
});

function hideAllToolTips() {
  openTooltips.forEach((tooltip) => {
    tooltip.isVisible = false;
    tooltip.hide();
  });
}

if (isToucheDevice)  {
  window.document.body.addEventListener('touchstart', (e) => {
    hideAllToolTips();
  });

  window.document.addEventListener('scroll', (event) => {
    if (Math.abs(onlastOpenScrolPos - window.scrollY) > MINSCROLLTOHIDEALL) {
      hideAllToolTips();
    }
  });
}
