import { InvinitySroll } from '../Search/InvinitySroll';

const LOADING_CLASS = 'is-loading';

const HISTORY_SUPPORT = !!(history && history.pushState);
const FINDER_FILTER_ID = '#finderSearch';

const FINDER_RESET_BUTTON_CL = '.js-reset-finder';
const FINDER_RESET_ID = '#finderReset';
const FINDER_FILTER_CONTAINER_ID = '#finderFilterAccordion';

const FILTER_SEARCH_INPUT_CLASS = 'filter__search-text-input';
const CHECKBOX_CLASS = 'js-finderfilter-check';
const SERACH_INPUT_ID = '#finderSearchInput';

const MODAL_BUTTON_FILTER_ID = '#JsFacetOpen';
const MODAL_BUTTON_SEARCH_ID = '#JsTextSearchOpen';

const FINDER_FILTER_FACEETS_CLASS = '.finderfilter__accordionitem';

const PAGINATION_LINK_SELECTOR = '.page-link';
const HAS_SELECTION_CLASS = 'hasSelected';


// NEW
const STATE = {
  init: 'init',
  ready: 'ready',
  updateUri: 'updateUri',
  searching: 'searching',
  nextPage: 'nextPage',
  startsearch: 'startsearch',
};
const SOLR_CONTAINER_CLASS = '.tx_solrscm_finder';
const solrContainer = document.querySelector(SOLR_CONTAINER_CLASS);
if (solrContainer) {
  solrContainer.setAttribute('data-solr-state', STATE.ready);
}
class Finder {
  constructor(el) {
    this.el = el;
    this.pageType = el.getAttribute('data-page-type');
    this.recordUid = el.getAttribute('data-record');
    this.searchTimeout = null;
    this.abortController = new AbortController();

    this.initElements();
    this.initSelectedFilter();
    this.initEvenlistener();

    this.invintiyScroll = new InvinitySroll();
  }

  initElements() {
    this.namespace = solrContainer.getAttribute('data-solr-namespace');
    this.currentSTATE = STATE.init;
    // TODO: celanup
    if (document.querySelector('.page-link')) {
      document.querySelector('.page-link').classList.remove('d-none');
    }

    this.finderFilterElement = document.querySelector(FINDER_FILTER_ID);

    this.finderResetButtons = document.querySelectorAll(FINDER_RESET_BUTTON_CL);
    this.finderResetButton = document.querySelector(FINDER_RESET_ID);
    this.finderFilterAccordion = document.querySelector(FINDER_FILTER_CONTAINER_ID);

    this.checkboxes = document.getElementsByClassName(CHECKBOX_CLASS);
    this.searchInput = document.querySelector(SERACH_INPUT_ID);

    this.filterbutton = document.querySelector(MODAL_BUTTON_FILTER_ID);
    this.searchbutton = document.querySelector(MODAL_BUTTON_SEARCH_ID);

    this.filterFacets = document.querySelectorAll(FINDER_FILTER_FACEETS_CLASS);
  }

  initEvenlistener() {
    window.addEventListener('popstate', (event) => {
      this._search(`${document.location}`);
    });

    this.el.addEventListener('keyup', (event) => {
      if (event.target.classList.contains(FILTER_SEARCH_INPUT_CLASS)) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          event.target.form.requestSubmit();
        }, 300);
      }
    });

    // finder click events
    this.finderFilterElement.addEventListener('click', (e) => {
      // open close finder
      const newFilterState = e.target.getAttribute('data-toggle-filter');
      if (newFilterState) {
        this.toggleFilter(newFilterState);
        return;
      }
      // reset search
      if (e.target.hasAttribute('data-reset-filter')) {
        this.resetSearch();
      }
    });

    this.finderFilterAccordion.addEventListener('change', (event) => {
      if (event.target.classList.contains(CHECKBOX_CLASS)) {
        const uri = event.target.getAttribute('data-uri');
        this.doSearch(uri);
      }
    });

    this.el.addEventListener('submit', (event) => {
      event.preventDefault();
      const queryString = new URLSearchParams(new FormData(event.target)).toString();
      let uri = event.target.getAttribute('action');
      const glue = uri.indexOf('?') === -1 ? '?' : '&';
      uri += glue + queryString;
      this.doSearch(uri);
    });
  }

  resetSearch() {
    // this.searchInput.value = '';
    // console.log(`${window.location.origin}${window.location.pathname}`);
    // this._search(`${window.location.origin}${window.location.pathname}`);
    window.location = window.location.origin + window.location.pathname;
  }

  // eslint-disable-next-line class-methods-use-this
  toggleFilter(targetState) {
    const currentState = document.body.getAttribute('data-modal-mode');
    const close = currentState === targetState ? true : targetState === 'close';
    document.body.setAttribute('data-modal-mode', close ? '' : targetState);
    
    if (!close && targetState === 'search') {
      clearTimeout(this.focusTimeOut);
      this.focusTimeOut = setTimeout((e) => {
        this.searchInput.focus();
      }, 150);
    }

    document.documentElement.classList.toggle('no-scroll', !close);
  }

  doSearch(uri) {
    clearTimeout(this.searchTimeout);
    if (HISTORY_SUPPORT) {
      history.pushState({}, '', uri);
    }
    this._search(uri);
  }

  _search(uri) {
    document.querySelector('.tx_solr').scrollIntoView();
    this.abortController.abort();
    const glue = uri.indexOf('?') === -1 ? '?' : '&';
    uri += `${glue}type=${this.pageType}&record=${this.recordUid}`;
    this.el.classList.add(LOADING_CLASS);
    fetch(uri, {
      method: 'get',
    }).then((res) => res.text()).then((html) => {
      this.el.classList.remove(LOADING_CLASS);
      this.updateSearch(html);
    }).catch((err) => {
      console.warn('Something went wrong.', err);
      this.el.classList.remove(LOADING_CLASS);
    });
  }

  updateSearch(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    for (const el of doc.getElementsByClassName('js-ajax-replace')) {
      const id = el.getAttribute('id');
      const node = document.getElementById(id);
      node.innerHTML = el.innerHTML;
    }
    // upadte dots
    this.initSelectedFilter();
    // pagination trigger
    this.invintiyScroll.initPagination();
  }

  // add "dots"
  initSelectedFilter() {
    let hasCriterion = false;

    this.filterbutton.classList.remove(HAS_SELECTION_CLASS);
    // loop throu facets
    for (let f = 0; f < this.filterFacets.length; f++) {
      this.filterFacets[f].classList.remove(HAS_SELECTION_CLASS);
      const inputs = this.filterFacets[f].querySelectorAll('.js-finderfilter-check');
      // loop inputs in facet
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
          this.filterFacets[f].classList.add(HAS_SELECTION_CLASS);
          this.filterbutton.classList.add(HAS_SELECTION_CLASS);
          this.finderResetButton.classList.add(HAS_SELECTION_CLASS);
          hasCriterion = true;
          break;
        }
      }
    }
    if (this.searchInput.value) {
      this.searchbutton.classList.add(HAS_SELECTION_CLASS);
      hasCriterion = true;
    } else {
      this.searchbutton.classList.remove(HAS_SELECTION_CLASS);
    }

    this.finderResetButton.classList.toggle(HAS_SELECTION_CLASS, hasCriterion);
  }

  initSelectedSearch() {
    this.searchbutton.classList.toggle(HAS_SELECTION_CLASS, this.searchInput.value != '');
  }
}
// named export (same as previous code snippet)
export { Finder };
