const textModals = document.querySelectorAll('[data-bs-toggle="text"]');

function initModal() {
  textModals.forEach((element) => {
    element.addEventListener('click', () => {
      const id = element.getAttribute('data-bs-target');
      const targetEl = document.querySelector(id);
      const contentHeight = targetEl.querySelector('.infobox-text-content').getBoundingClientRect().height;

      targetEl.style.setProperty('--text-modal-height', `${contentHeight}px`);
      targetEl.classList.toggle('show');
    });
  });
}

if (textModals && textModals.length) {
  initModal();
}
