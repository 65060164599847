import { Dropdown } from 'bootstrap';

class DownloadWarning {
  constructor() {
    const downloadWarningLinks = document.querySelectorAll('.link-warning-download');
    const translateEl = document.querySelector('[data-mask-translate]');
    // get translated text (only in mask element available) 
    const wraningText = translateEl ? translateEl.getAttribute('data-download-warning-text') : 'attention!';
    const wraningButtonText = translateEl ? translateEl.getAttribute('data-download-warning-button') : 'agree';

    downloadWarningLinks.forEach((link) => {

      // create a boostrap dropdown menu
      const wrapper = this.createElementWithClasses('span', ['dropdown', 'dropdown-download-warning']);
      const dropdownMenu = this.createElementWithClasses('div', ['dropdown-menu']);
      const dropdownTitle = this.createElementWithClasses('span', ['dropdownShare__title']);
      dropdownTitle.innerHTML = wraningText;
      const downloadLink = this.createElementWithClasses('a');
      downloadLink.href = link.href;
      downloadLink.innerHTML = `<span class="downloadlist__icon"><button type="button" class="btn btn-outline"><svg viewBox="0 0 24 24" class="icon"><use href="#icon-download"></use></svg><span>${wraningButtonText}</span></button></span>`;

      // reset default link and add dropdown attributes
      link.href = '#';
      link.removeAttribute('target');
      link.setAttribute('data-bs-toggle', 'dropdown');
      link.parentNode.insertBefore(wrapper, link);

      dropdownMenu.appendChild(dropdownTitle);
      dropdownMenu.appendChild(downloadLink);
      wrapper.appendChild(link);
      wrapper.appendChild(dropdownMenu);

      this.initDropdown(link);
    });
  }

  createElementWithClasses(tag, classes) {
    const el = document.createElement(tag);
    if (classes) {
      classes.forEach((cl) => {
        el.classList.add(cl);
      });
    }
    return el;
  }

  initDropdown(dropdownEl) {
    return new Dropdown(dropdownEl);
  }
}
// named export (same as previous code snippet)
export default DownloadWarning;
