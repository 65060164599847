// toggle main menu
const menutoggler = document.querySelectorAll('.js-toggle-menu');
let currScrollPos = window.scrollY;

/** ************ Scroll MENU BEHAVIOR  ************* */
let lastKnownScrollPosition = 0;
let scrollDirection = 0;
const scrollTopTolerance = 75;
const minScrollDist = 40;
let ticking = false;

/** ************ HOME HEADER ANIMATION  ************* */
const header = document.querySelector('header');

/** ************ HOME ANIMATION * ************* */
const mainnav = document.querySelector('#mainnav');
const maintopiclinks = header.querySelectorAll('.js-mainmenu-link');
const maintopiclparent = header.querySelectorAll('.js-mainmenu-parent');

const closeSubmenu = function (mainnavItem) {
  mainnav.style.setProperty('--center-offset', `${0}px`);
  mainnav.classList.remove('mainnav-opensub');
  if (mainnavItem) {
    mainnavItem.classList.remove('open');
  } else {
    const openitem = document.querySelectorAll('.mainmenu_item.open');
    if (openitem) {
      openitem.forEach((el) => el.classList.remove('open'));
    }
  }
  document.activeElement.blur();
};

function toggleMainMenu() {
  if (document.body.classList.contains('menu-open')) {
    closeSubmenu();
    document.body.classList.remove('menu-open');
    document.body.style.top = '';
    window.scrollTo({ top: currScrollPos, left: 0, behavior: 'instant' });
    scrollDirection = -1; // don't hide menu
  } else {
    currScrollPos = window.scrollY;
    document.body.style.top = `${-currScrollPos}px`;
    document.body.classList.add('menu-open');
  }
}

menutoggler.forEach((button) => {
  button.addEventListener('click', toggleMainMenu);
});

/** ************ HOME ANIMATION
 * not used yet
 * ************* */

maintopiclinks.forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    closeSubmenu();
    mainnav.classList.add('fadeout');
    setTimeout((url) => {
      window.location = url;
    },
    180,
    e.target.href);
  });
});

maintopiclparent.forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const mainnavItem = e.target.closest('.mainmenu_item');
    if (mainnavItem.classList.contains('open')) {
      closeSubmenu(mainnavItem);
    } else {
      // open sub nav
      const childPos = mainnavItem.getBoundingClientRect();
      const offset = ((window.innerHeight - 90) * 0.5 + 90) - (childPos.top + childPos.height);
      // 90 == header height!
      mainnav.style.setProperty('--center-offset', `${offset}px`);
      mainnavItem.classList.add('open');
      mainnav.classList.add('mainnav-opensub');
      document.activeElement.blur();
    }
  });
});

/** ************ BACK BUTTON  ************* */
const referrer = document.referrer.split('?')[0]; // remove all get parameters from referrer
const _backbutton = document.querySelectorAll('[data-back-button]');
if (_backbutton.length > 0) {
  if (referrer.indexOf(window.location.hostname) > -1 && referrer != '') {
    // The referrer is schweizerkaese
    for (let i = 0, l = _backbutton.length; i < l; i++) {
      _backbutton[i].setAttribute('href', 'javascript:history.back()');
    }
  }
}

/** ************ Scroll MENU BEHAVIOR  ************* */
function updateScroll(scrollPos) {
  if (scrollDirection === 1) {
    document.body.classList.add('body-scroll-down');
  } else {
    document.body.classList.remove('body-scroll-down');
  }

  if (scrollPos < scrollTopTolerance) {
    document.body.classList.remove('body-scrolled');
  } else {
    document.body.classList.add('body-scrolled');
  }
}

document.addEventListener('scroll', () => {
  const currentScrollPos = Math.max(window.scrollY, 0);
  const currentScrollDist = Math.abs(currentScrollPos - lastKnownScrollPosition);
  if (currentScrollDist < minScrollDist) {
    return;
  }
  // update scroll data
  scrollDirection = Math.sign(currentScrollPos - lastKnownScrollPosition);
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(() => {
      updateScroll(lastKnownScrollPosition);
      ticking = false;
    });

    ticking = true;
  }
});

updateScroll(window.scrollY);
